<template>
  <v-container fill-height>
    <div class="form-container mx-auto">
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <div align="center">
            <v-icon style="color: #2a95ae; font-size: 100px;">mdi-account-circle</v-icon>
          </div>
          <v-card-title class="d-flex justify-center align-center">
            <div class="text my-3">USER</div>
            <div class="text mx-2" style="color:#2a95ae">PROFILE</div>
          </v-card-title>
          <v-text-field rounded dense v-model="firstName" label="First Name" outlined clearable></v-text-field>
          <v-text-field rounded dense v-model="lastName" label="Last Name" outlined clearable></v-text-field>
          <v-text-field rounded dense v-model="email" label="Email Address" outlined clearable></v-text-field>
          <v-text-field rounded dense v-model="cellNo" label="Cell Number" outlined clearable></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <!-- <v-btn color="#2a95ae" text @click="resetPassword">Reset Password</v-btn> -->
        <v-btn color="red" text @click="deleteUser">Delete User</v-btn>
        <v-alert class="my-3" :value="alert.show" :type="alert.type" dismissible @input="alert.show=false">
          {{ alert.message }}
        </v-alert>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { collection, getDocs, getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  data() {
    return {
      showPassword: false,
      fullName: '',
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      cellNo: '',
      password: '',
      drawerOpen: false,
      drawerItems: [],
      alert: {
        show: false,
        message: '',
        type: 'success'
      }
    };
  },
  async mounted() {
    await this.fetchUserData();
  },
  methods: {
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    navigate(route) {
      this.$router.push(route);
    },
    async fetchUserData() {
      const db = getFirestore(firebaseApp);
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("No userId found in local storage.");
        return;
      }

      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        this.email = userData.email || '';
        this.firstName = userData.firstName || '';
        this.lastName = userData.lastName || '';
        this.cellNo = userData.cellNo || '';
      } else {
        console.log("No such document!");
      }
    },
    async resetPassword() {
      const auth = getAuth(firebaseApp);
      try {
        await sendPasswordResetEmail(auth, this.email);
        this.alert.message = 'Password reset email sent.';
        this.alert.type = 'success';
        this.alert.show = true;
      } catch (error) {
        console.error('Error sending password reset email:', error);
        this.alert.message = 'Failed to send password reset email: ' + error.message;
        this.alert.type = 'error';
        this.alert.show = true;
      }
    },
    async deleteUser() {
      const functions = getFunctions(firebaseApp);
      const deleteUserFunction = httpsCallable(functions, 'deleteUser');
      const userId = localStorage.getItem('userId');
      this.$router.push('/'); // Navigate to home page after successful registration

      try {
        const result = await deleteUserFunction({ userId });
        console.log(result.data.message);
        this.alert.message = 'User successfully deleted.';
        this.alert.type = 'success';
        this.alert.show = true;
        // Optionally, clear user-related data and navigate away
      } catch (error) {
        console.error('Error deleting user:', error);
        this.alert.message = 'Failed to delete user: ' + error.message;
        this.alert.type = 'error';
        this.alert.show = true;
      }
    }
  }
};
</script>

<style scoped>
.form-container {
  max-width: 600px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.form-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.display-1 {
  margin: 0;
  color: #2a95ae; /* Adjust the color to match your primary color */
}

.v-icon {
  color: #2a95ae; /* Match icon color with the scheme */
}

/* Further adjustments for text fields and other elements */
</style>
