<template>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <div class="elevation-12" outlined tile style="border-radius:20px;background-color:white;">
                      <v-card-title class="d-flex justify-center align-center">


              <v-img src="@/assets/Pocket.png"  contain height="200" />

            </v-card-title>
  
            <v-card-text class="text-center subheading" style="font-weight: 300;">
                <h3>
            Welcome to Pocket Manager please click "Continue" to start your chat journey.
            </h3>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn dense rounded width="120px"  color="#2a95ae" dark  @click="continueJourney">
                Continue
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    methods: {
      continueJourney() {
        // Navigate to the chat page
        this.$router.push('/home');
      }
    }
  }
  </script>
  
  <style scoped>
  /* Here you can add additional styles if necessary */
  </style>
  