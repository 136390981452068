// import {initializeApp, auth} from "firebase/compat/app"
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyByt9qdm2wVBaLGVysWhh_b3RvBWhh1_qY",
  authDomain: "trifocus-51b3c.firebaseapp.com",
  projectId: "trifocus-51b3c",
  storageBucket: "trifocus-51b3c.appspot.com",
  messagingSenderId: "48617918358",
  appId: "1:48617918358:web:735d4db3afb2b707c357b7",
  measurementId: "G-460C3CPDPW"
};
export const firebaseApp = initializeApp(firebaseConfig);
// export const auth = firebaseApp.auth();

export const auth = getAuth();
