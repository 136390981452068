<template>
  <div class="backgroundStyle d-flex justify-center align-center">
    <v-col cols="11" sm="10" md="3" justify="center" class="font-family: 'Poppins', sans-serif;">
      <v-card class="pa-4 card d-flex justify-center align-center" style="border-radius:20px; margin-top:30px;">
        <v-col cols="11">
          <v-row>
            <v-icon @click="goToPage('/')" style="font-size:35px" color="#2a95ae">mdi-step-backward</v-icon>
          </v-row>
          <v-row justify="center" class="mb-5">
            <v-img src="../assets/Pocket.png" contain height="200" width="100"></v-img>
          </v-row>
          <!-- Form fields -->
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="name" dense required outlined rounded block label="Name" type="text" size="x-large" style="max-width: 301px; color: #EEBF00;"
              :error-messages="nameErrors" @blur="validateName"></v-text-field>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="surname" dense required outlined rounded block label="Surname" type="text" size="x-large" style="max-width: 301px; color: #EEBF00;"
              :error-messages="surnameErrors" @blur="validateSurname"></v-text-field>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="email" dense required outlined rounded block label="Email" type="text" size="x-large" style="max-width: 301px; color: #EEBF00;"
              :error-messages="emailErrors" @blur="validateEmail"></v-text-field>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="number" dense required outlined rounded block label="Number" type="text" size="x-large" style="max-width: 301px; color: #EEBF00;"
              :error-messages="numberErrors" @blur="validateNumber"></v-text-field>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="password" dense required outlined rounded block label="Password" :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="togglePasswordVisibility"
              :error-messages="passwordErrors" @blur="validatePassword" style="max-width: 301px; color: #EEBF00;"></v-text-field>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="confirmPassword" dense required outlined rounded block label="Confirm Password" :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="toggleConfirmPasswordVisibility"
              :error-messages="confirmPasswordErrors" @blur="validateConfirmPassword" style="max-width: 301px; color: #EEBF00;"></v-text-field>
          </v-row>

          <!-- Terms and Conditions -->
          <v-row @click="goToTerms" justify="center" class="d-flex align-center" style="margin-top: -30px">
            <v-checkbox v-model="termsAccepted" :error-messages="termsErrors" label="I agree to the terms and conditions" color="#2a95ae"></v-checkbox>
          </v-row>

          <div class="my-1" style="max-width: 161px; margin: 0 auto;">
            <v-btn @click="register" class="my-6" color="#2a95ae" block dark rounded dense>Register</v-btn>
          </div>

          <!-- v-dialog to display the iframe -->
          <v-dialog v-model="dialog" max-width="800px">
            <v-card style="border-radius:20px;">
              <v-card-title class="text">Terms and Conditions</v-card-title>
              <v-card-text>
                <iframe :src="iframeSrc" width="100%" height="600px" frameborder="0"></iframe>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from "../firebaseConfig";
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore";

export default {
  data() {
    return {
      backgroundStyle: {
        'background-image': 'url(' + require('../assets/Background.webp') + ')',
        'background-size': 'cover'
      },
      name: '',
      surname: '',
      email: '',
      number: '',
      password: '',
      confirmPassword: '',
      termsAccepted: false,
      nameErrors: [],
      surnameErrors: [],
      emailErrors: [],
      numberErrors: [],
      passwordErrors: [],
      confirmPasswordErrors: [],
      termsErrors: [],
      showPassword: false,
      showConfirmPassword: false,
      dialog: false,
      iframeSrc: "https://firebasestorage.googleapis.com/v0/b/trifocus-51b3c.appspot.com/o/Pocket_Manager_Terms_and_Conditions_Single_Page.pdf?alt=media&token=d7cffb85-0661-447b-911d-ba6e0587df0e"
    };
  },
  computed: {
    isRegisterFormValid() {
      return this.nameErrors.length === 0 && this.surnameErrors.length === 0 && this.emailErrors.length === 0 &&
        this.numberErrors.length === 0 && this.passwordErrors.length === 0 && this.confirmPasswordErrors.length === 0 &&
        this.name.trim() !== '' && this.surname.trim() !== '' && this.email.trim() !== '' && this.number.trim() !== '' &&
        this.password.trim() !== '' && this.confirmPassword.trim() !== '' && this.termsAccepted;
    }
  },
  methods: {
    goToPage(page) {
      if (this.$route.path !== page) {
        this.$router.push(page).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    validateName() {
      this.nameErrors = [];
      if (this.name.trim() === '') {
        this.nameErrors.push("Name is required.");
      }
    },
    goToTerms() {
      window.open(this.iframeSrc, '_blank');
    },
    validateSurname() {
      this.surnameErrors = [];
      if (this.surname.trim() === '') {
        this.surnameErrors.push("Surname is required.");
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailErrors = [];
      if (this.email.trim() === '') {
        this.emailErrors.push("Email is required.");
      } else if (!emailPattern.test(this.email.trim())) {
        this.emailErrors.push("Invalid email format.");
      }
    },
    validateNumber() {
      this.numberErrors = [];
      if (this.number.trim() === '') {
        this.numberErrors.push("Number is required.");
      }
    },
    validatePassword() {
      this.passwordErrors = [];
      if (this.password.trim() === '') {
        this.passwordErrors.push("Password is required.");
      } else if (this.password.length < 6) {
        this.passwordErrors.push("Password must be at least 6 characters long.");
      }
    },
    validateConfirmPassword() {
      this.confirmPasswordErrors = [];
      if (this.confirmPassword.trim() === '') {
        this.confirmPasswordErrors.push("Confirm Password is required.");
      } else if (this.confirmPassword !== this.password) {
        this.confirmPasswordErrors.push("Passwords do not match.");
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    showIframe() {
      this.dialog = true;
    },
    async register() {
      this.validateName();
      this.validateSurname();
      this.validateEmail();
      this.validateNumber();
      this.validatePassword();
      this.validateConfirmPassword();
      this.validateTerms();

      if (this.isRegisterFormValid) {
        const auth = getAuth(firebaseApp);
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
          const user = userCredential.user;
          const userId = user.uid;

          const db = getFirestore(firebaseApp);
          const userRef = doc(db, "users", userId);
          await setDoc(userRef, {
            firstName: this.name,
            lastName: this.surname,
            email: this.email,
            disabled: false,
            departmentId:"tGrowAqRDMKJSiEdzgQj",
            cellNo: this.number,
            createdAt: serverTimestamp(),
          });

          console.log("User created successfully with ID:", userId);
          this.$router.push('/'); // Navigate to home page after successful registration
        } catch (error) {
          console.error("Error creating user:", error.code, error.message);
        }
      } else {
        console.log('Please correct the errors in the form.');
      }
    },
    validateTerms() {
      this.termsErrors = [];
      if (!this.termsAccepted) {
        this.termsErrors.push("You must accept the terms and conditions.");
      }
    }
  }
};
</script>

<style scoped>
.backgroundStyle {
  width: 100%;
  height: 100%;
  background: url("../assets/Background.png") center center / cover no-repeat;
}
.text {
  color: #2a95ae;
}
</style>
