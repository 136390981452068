<template>
    <v-container fill-height>
      <div class="form-container mx-auto">
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" lg="6">
            <div align="center">
              <v-icon style="color: #2a95ae; font-size: 100px;">mdi-shield-account</v-icon>
            </div>
            <v-card-title class="d-flex justify-center align-center">
              <div class="text my-3">PRIVACY</div>
              <div class="text mx-2" style="color:#2a95ae">POLICY</div>
            </v-card-title>
            <p class="policy-text"><strong>Effective Date:</strong> 5/14/2024</p>
            <p class="policy-text"><strong>Introduction:</strong> Welcome to Pocket Manager, your personal chat AI assistant. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application, Pocket Manager (the "App"), available on Google Play Store and Apple App Store.</p>
            <p class="policy-text"><strong>2. Information We Collect:</strong></p>
            <p class="policy-text"><strong>2.1. Personal Data:</strong> When you use the App, we may collect personal information that can be used to identify you, such as your name, email address, and phone number.</p>
            <p class="policy-text"><strong>2.2. Usage Data:</strong> We collect information about your interactions with the App, including the pages or screens you view, your interactions with features, and the time and date of your visits.</p>
            <p class="policy-text"><strong>2.3. Device Data:</strong> We collect information about the device you use to access the App, including the hardware model, operating system, IP address, and device identifiers.</p>
            <p class="policy-text"><strong>3. How We Use Your Information:</strong></p>
            <p class="policy-text"><strong>3.1. To Provide and Maintain the App:</strong> We use your information to provide, maintain, and improve the App's functionality and user experience.</p>
            <p class="policy-text"><strong>3.2. To Communicate with You:</strong> We may use your personal information to send you updates, notifications, and other communications related to the App.</p>
            <p class="policy-text"><strong>3.3. To Improve Our Services:</strong> We analyze usage data to understand how users interact with the App and to improve its features and performance.</p>
            <p class="policy-text"><strong>4. Sharing Your Information:</strong></p>
            <p class="policy-text"><strong>4.1. With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as hosting and data analysis.</p>
            <p class="policy-text"><strong>4.2. For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
            <p class="policy-text"><strong>5. Data Security:</strong> We use reasonable administrative, technical, and physical security measures to protect your information from unauthorized access, use, or disclosure.</p>
            <p class="policy-text"><strong>6. Your Data Rights:</strong></p>
            <p class="policy-text"><strong>6.1. Access and Correction:</strong> You have the right to access and correct your personal information. You can request a copy of your data and make corrections by contacting us at Notifications@pocketmanager.io.</p>
            <p class="policy-text"><strong>6.2. Deletion:</strong> You can request the deletion of your personal information by contacting us at Notifications@pocketmanager.io. We will delete your data unless we are required to retain it for legal reasons.</p>
            <p class="policy-text"><strong>7. Children's Privacy:</strong> The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete it.</p>
            <p class="policy-text"><strong>8. Changes to This Privacy Policy:</strong> We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date.</p>
            <p class="policy-text"><strong>9. Contact Us:</strong> If you have any questions about this Privacy Policy, please contact us at Notifications@pocketmanager.io.</p>
            <p class="policy-text">---<br>By using Pocket Manager, you agree to the terms of this Privacy Policy. Thank you for trusting us with your personal information.<br>---</p>
            <p class="policy-text"><strong>Contact Information:</strong><br>Email: Notifications@pocketmanager.io<br>Address:31 33 Fricker Road Illovo</p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        effectiveDate: "Effective Date: 5/14/2024",
        introduction: "Welcome to Pocket Manager, your personal chat AI assistant. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application, Pocket Manager (the 'App'), available on Google Play Store and Apple App Store.",
        informationWeCollect: `2. Information We Collect
          2.1. Personal Data
          When you use the App, we may collect personal information that can be used to identify you, such as your name, email address, and phone number.
          2.2. Usage Data
          We collect information about your interactions with the App, including the pages or screens you view, your interactions with features, and the time and date of your visits.
          2.3. Device Data
          We collect information about the device you use to access the App, including the hardware model, operating system, IP address, and device identifiers.`,
        howWeUseInformation: `3. How We Use Your Information
          3.1. To Provide and Maintain the App
          We use your information to provide, maintain, and improve the App's functionality and user experience.
          3.2. To Communicate with You
          We may use your personal information to send you updates, notifications, and other communications related to the App.
          3.3. To Improve Our Services
          We analyze usage data to understand how users interact with the App and to improve its features and performance.`,
        sharingInformation: `4. Sharing Your Information
          4.1. With Service Providers
          We may share your information with third-party service providers who perform services on our behalf, such as hosting and data analysis.
          4.2. For Legal Reasons
          We may disclose your information if required to do so by law or in response to valid requests by public authorities.`,
        dataSecurity: "5. Data Security We use reasonable administrative, technical, and physical security measures to protect your information from unauthorized access, use, or disclosure.",
        dataRights: `6. Your Data Rights
          6.1. Access and Correction
          You have the right to access and correct your personal information. You can request a copy of your data and make corrections by contacting us at Notifications@pocketmanager.io.
          6.2. Deletion
          You can request the deletion of your personal information by contacting us at Notifications@pocketmanager.io. We will delete your data unless we are required to retain it for legal reasons.`,
        childrensPrivacy: "7. Children's Privacy The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete it.",
        policyChanges: "8. Changes to This Privacy Policy We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date.",
        contactUs: "9. Contact Us If you have any questions about this Privacy Policy, please contact us at Notifications@pocketmanager.io. --- By using Pocket Manager, you agree to the terms of this Privacy Policy. Thank you for trusting us with your personal information. --- Contact Information: Email: Notifications@pocketmanager.io Address: [Company Address]"
      };
    }
  };
  </script>
  
  <style scoped>
  .form-container {
    max-width: 600px;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .v-icon {
    color: #2a95ae;
  }
  
  .v-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .policy-text {
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
  }
  
  .policy-text strong {
    color: #2a95ae;
  }
  </style>
  