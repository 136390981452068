<template>
  <div>
    <v-navigation-drawer
    v-if="$route.path != '/' && $route.path != '/forgotPassword' && $route.path !='/signUp' && $route.path !='/privacyPolicy'"

      v-model="drawer"
      app
      elevation="0"
      class="navigation-drawer pa-4"

    >
    <v-row justify="center" class="my-12">
    <v-img src="../assets/Pocket.png" contain height="110"></v-img>
  </v-row>
  
      <v-list dense style="margin-top:70px">
        <v-list-item
        :class="{ 'active-item': isActiveItem(item.route) }"

          v-for="(item, index) in drawerItems"
          :key="index"
          @click="navigate(item.route)"
        >
          <v-list-item-icon>
            <v-icon class="v-list-item-title">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="v-list-item-title">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="$route.path != '/' && $route.path != '/forgotPassword' && $route.path !='/signUp' && $route.path !='/privacyPolicy'"
      style="background-color: rgb(255, 255, 255)"
      app
      flat
    >
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-btn
          icon="$vuetify"
          rounded
          style="background-color: #2a95ae; height: 35px; width: 36px"
          ><v-icon color="white">mdi-menu</v-icon></v-btn
        >
      </v-app-bar-nav-icon>

      <v-row justify="center" class="my-1 mx-2" style="margin-right: 35px"
        ><h2 style="color: rgb(0, 0, 0)" class="text">POCKET</h2>
        <h2 style="color: #2a95ae" class="text mx-1">MANAGER</h2></v-row
      >

      <!-- Other app bar components -->
    </v-app-bar>
    <!-- Your page content here -->
  </div>
</template>

<script>
import { collection, addDoc, getFirestore } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth"; // Import signOut and getAuth

import { firebaseApp } from "../firebaseConfig";

const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      drawer: false,

      drawerItems: [
        { title: "Chat Bot", icon: "mdi-account-details", route: "/home" },
        { title: "Profile", icon: "mdi-account", route: "/profile" },
        { title: "Privacy Policy", icon: "mdi-file-document-multiple", route: "/privacy" },
        { title: "Logout", icon: "mdi-logout", route: "/" },
      ],
    };
  },
  methods: {
    isActiveItem(route) {
    return this.$route.path === route;
  },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    navigate(route) {
  // Special case for logout
  if (route === "/") {
    this.logout(); // Call logout method
    return; // Exit early so router push isn't called
  }
  // Check if the target route is the same as the current route to avoid redundant navigation
  if (this.$router.currentRoute.path !== route) {
    this.$router.push(route).catch(err => {
      // Catch the NavigationDuplicated error here and ignore it
      if (err.name !== 'NavigationDuplicated') {
        // If the error is something else, you might want to do something with it or throw it
        throw err;
      }
      // Optional: Perform any action when the navigation is duplicated, or simply ignore
    });
  }
},

logout() {
  const auth = getAuth(firebaseApp);
  signOut(auth).then(() => {
    // After logging out, redirect to login page only if not already there
    if (this.$router.currentRoute.path !== '/') {
      this.$router.push('/').catch(err => {
        // Catch and ignore the error if it's due to redundant navigation
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    }
  }).catch((error) => {
    // Handle errors during the logout process
    console.error("Logout failed: ", error);
  });
}

  },
};
</script>

<style >


.navigation-drawer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%; /* Adjust width to ensure there's no visible outline */
  height: 100%;

  z-index: -1;
}

.text{
  font-family: "Black Han Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.navigation-drawer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%; /* Adjust width to ensure there's no visible outline */
  height: 100%;

  z-index: -1;
}



.active-item .v-list-item-title {
  color: white; /* Sets the text color to white */
  
}
.active-item {
  border-radius: 20px; /* Circular edges */
  background-color: rgb(74, 149, 172); /* Light blue background for active item */
  color: white; /* White text color */
  font-family: "Black Han Sans", sans-serif;
}
</style>
