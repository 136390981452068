import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import Profile from '../views/Profile.vue';
import WelcomeTo from '../views/Welcome.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import Privacy from '../views/Privacy.vue';
import SignUp from '../views/SignUp.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/home', name: 'Home', component: Home },
  { path: '/privacy', name: 'Privacy', component: Privacy },
  { path: '/privacyPolicy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/signUp', name: 'SignUp', component: SignUp },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/welcomeTo', name: 'WelcomeTo', component: WelcomeTo },
  { path: '/forgotPassword', name: 'ForgotPassword', component: ForgotPassword },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const auth = getAuth();
router.beforeEach((to, from, next) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      if (to.path === '/' && from.path !== '/signUp' && from.path !== '/profile' && from.path !== '/privacyPolicy') {
        next('/home');
      } else {
        next();
      }
    } else {
      if (to.path !== '/' && to.path !== '/forgotPassword' && to.path !== '/signUp' && to.path !== '/privacyPolicy') {
        next('/');
      } else {
        next();
      }
    }
    unsubscribe();
  });
});

export default router;
