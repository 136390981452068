<template>
  <div class="backgroundStyle d-flex justify-center align-center">
    <v-col cols="12" sm="10" md="3" justify="center" class="font-family: 'Poppins', sans-serif;">
      <v-card class="card d-flex justify-center align-center pa-5" style="border-radius:20px;">
        <v-col>
          <v-alert v-if="loginError" type="error" dense dismissible @click="loginError = ''">
            {{ loginError }}
          </v-alert>

          <v-row justify="center" class="mb-5">
            <v-img src="../assets/Pocket.png" contain height="200" width="100"></v-img>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="email" dense required outlined rounded block label="Email" type="text"
              size="x-large" class="input-text-color"
              :error-messages="emailErrors"
              @blur="validateEmail"></v-text-field>
          </v-row>
          <v-row justify="center" class="d-flex align-center font-family: 'Poppins'" style="margin-bottom: 20px">
            <v-text-field v-model="password" dense required outlined rounded block label="Password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="togglePasswordVisibility"
              :error-messages="passwordErrors"
              @blur="validatePassword"
              class="input-text-color"></v-text-field>
          </v-row>
          <v-row class="mx-3" style="margin-top:-20px;" justify="start">
            <h5 @click="goToPage('/forgotPassword')">Forgot Password?</h5>
          </v-row>
          <div class="my-10" style="max-width: 161px; margin: 0 auto;">
            <v-btn @click="login" color="#2a95ae" block dark rounded dense>Login</v-btn>
          </div>

          <v-row class="my-4" justify="center">
            <h5 @click="goToPage('/signUp')">Sign Up</h5>
          </v-row>
        </v-col>
      </v-card>

      <!-- OTP Dialog -->
      <v-dialog v-model="otpDialog" width="450">
        <v-card class="d-flex flex-column justify-center align-center pa-5" style="border-radius:20px;">
          <v-card-title class="text mx-2" style="color:#2a95ae">Please check your Number</v-card-title>
          <v-card-subtitle class="text mt-1  mb-4">We've sent a code to {{ cellNo }}</v-card-subtitle>
          <v-otp-input v-model="otp" length="6" class="d-flex justify-center mb-4 mx-1 input-text-color"></v-otp-input>
          <v-btn color="#2a95ae" class="mb-4" block dark rounded dense @click="verifyOTP">Verify</v-btn>
          <v-btn text @click="resendOTP">Didn't get the code? <span class="text-decoration-underline">Click to resend</span></v-btn>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";

export default {
  data() {
    return {
      backgroundStyle: {
        'background-image': 'url(' + require('../assets/Background.webp') + ')',
        'background-size': 'cover'
      },
      email: '',
      emailErrors: [],
      password: '',
      passwordErrors: [],
      showPassword: false,
      loginError: '',
      otp: '',
      otpDialog: false,
      generatedOTP: '',
      userId: '',
      cellNo: '',
      otpExpired: false,
      otpStartTime: null,
    };
  },
  computed: {
    isLoginFormValid() {
      return this.emailErrors.length === 0 && this.passwordErrors.length === 0 && this.email.trim() !== '' && this.password.trim() !== '';
    }
  },
  watch: {
    loginError(newValue) {
      if (newValue !== '') {
        setTimeout(() => {
          this.loginError = '';
        }, 2000);
      }
    }
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailErrors = [];

      if (this.email.trim() === '') {
        this.emailErrors.push("Email is required.");
      } else if (!emailPattern.test(this.email.trim())) {
        this.emailErrors.push("Invalid email format.");
      }
    },
    validatePassword() {
      this.passwordErrors = [];

      if (this.password.trim() === '') {
        this.passwordErrors.push("Password is required.");
      } else if (this.password.length < 6) {
        this.passwordErrors.push("Password must be at least 6 characters long.");
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      this.validateEmail();
      this.validatePassword();

      if (this.isLoginFormValid) {
        const auth = getAuth(firebaseApp);
        try {
          const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
          const user = userCredential.user;
          this.userId = user.uid;
          localStorage.setItem('userId', this.userId);
          await this.fetchUserData();
          this.sendOTP();
        } catch (error) {
          if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password' || error.code === 'auth/invalid-credential') {
            this.loginError = 'Email or password is incorrect. Please try again.';
          } else {
            this.loginError = 'Login failed: ' + error.message;
          }
        }
      } else {
        let errorMessage = 'Please correct the following errors:\n';
        if (this.emailErrors.length > 0) {
          errorMessage += this.emailErrors.join('\n') + '\n';
        }
        if (this.passwordErrors.length > 0) {
          errorMessage += this.passwordErrors.join('\n');
        }
        this.loginError = errorMessage;
      }
    },
    async fetchUserData() {
      const db = getFirestore(firebaseApp);
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("No userId found in local storage.");
        return;
      }

      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        this.email = userData.email || '';
        this.firstName = userData.firstName || '';
        this.lastName = userData.lastName || '';
        this.cellNo = userData.cellNo || '';
      } else {
        console.log("No such document!");
      }
    },
    sendOTP() {
      const otp = Math.floor(100000 + Math.random() * 900000);
      this.generatedOTP = otp.toString();

      if (!this.cellNo || this.cellNo.trim() === '') {
        console.error('Error: Cell number is empty or contains only whitespace.');
        this.loginError = 'Cell number is required for OTP.';
        return;
      }

      this.simulateOTPSending(otp, this.cellNo);

      console.log('Generated OTP:', otp);
      this.otpDialog = true;
      this.otpExpired = false;
      this.otpStartTime = new Date().getTime();

      setTimeout(() => {
        this.otpExpired = true;
      }, 120000);
    },
    async simulateOTPSending(otp, cellNo) {
      const apiURL = 'https://rest.smsportal.com/v1/bulkmessages';
      const apiKey = 'a653c9fa-e9f7-40f8-b039-01fc7ecabf8f';
      const apiSecret = '122b0349-2a95-4d75-acd5-b279ae05f975';
      const message = `Hi There Your OTP For Pocket Manager: ${otp}`;

      const headers = new Headers({
        'Authorization': 'Basic ' + btoa(apiKey + ':' + apiSecret),
        'Content-Type': 'application/json'
      });

      const body = JSON.stringify({
        messages: [
          {
            content: message,
            destination: cellNo,
          },
        ],
      });

      try {
        const response = await fetch(apiURL, {
          method: 'POST',
          headers: headers,
          body: body,
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error response data:', errorData);
          throw new Error(`Error response status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('OTP sent successfully:', responseData);
      } catch (error) {
        console.error('Error sending OTP:', error.message);
      }
    },
    verifyOTP() {
      const currentTime = new Date().getTime();
      if (this.otpExpired || (currentTime - this.otpStartTime) > 120000) {
        this.loginError = 'OTP has expired. Please request a new one.';
        return;
      }

      if (this.otp === this.generatedOTP) {
        this.$router.push('/home');
      } else {
        this.loginError = 'Invalid OTP. Please try again.';
      }
    },
    resendOTP() {
      this.sendOTP();
    },
    goToPage(page) {
      this.$router.push(page);
    },
    checkAuthState() {
      const auth = getAuth(firebaseApp);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.$router.push('/home');
        }
      });
    }
  }
};
</script>

<style scoped>
.backgroundStyle {
  width: 100%;
  height: 100%;
  background: url("../assets/Background.png") center center / cover no-repeat;
}
.otp-input {
  justify-content: space-between;
}
.input-text-color input {
  color: #000000 !important;
}
</style>
