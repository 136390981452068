<template>
  <v-container style="background-color:white" v-if="iframeLink">
    <iframe :src="iframeLink" width="100%" cover class="frame"></iframe>
  </v-container>
</template>

<script>
import {
  doc,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { watch } from 'vue';

const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      iframeLink: null, // This will hold the link to be displayed in the iframe
    };
  },
  created() {
    this.initializeIframeLink(); // Initialize or fetch the link when the component is created
  },
  mounted() {
    this.initializeIframeLink(); // Ensure link initialization when the component is mounted
  },
  methods: {
    async fetchIframeLink() {
      const userId = localStorage.getItem("userId");

      if (!userId) {
        console.error("No userId found in localStorage");
        return;
      }

      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        console.error("No matching user document found");
        return;
      }

      const userDocData = userDocSnap.data();
      const departmentId = userDocData.departmentId;

      if (!departmentId) {
        console.error("User document does not contain a departmentId");
        return;
      }

      const departmentDocRef = doc(db, "department", departmentId);
      const departmentDocSnap = await getDoc(departmentDocRef);

      if (!departmentDocSnap.exists()) {
        console.error("No matching department found");
        return;
      }

      this.iframeLink = departmentDocSnap.data().link;
    },
    initializeIframeLink() {
      const storedLink = localStorage.getItem('iframeLink');
      if (storedLink) {
        this.iframeLink = storedLink; // Use stored link
      } else {
        this.fetchIframeLink(); // Fetch new link if not stored
      }
    }
    ,
    
  },
  watch: {
    iframeLink(newVal) {
      if (newVal && !localStorage.getItem('refreshDone')) {
        localStorage.setItem('iframeLink', newVal);
        localStorage.setItem('refreshDone', '1'); // Indicate that the refresh has occurred
        window.location.reload();
      }
    }
  }
};
</script>

<style scoped>
.frame {
  height: 91vh;
  border-radius: 20px;
}
</style>
